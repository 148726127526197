function moveElement(detachingEle, attachingEle) {
  let img = $('.' + detachingEle).detach();
  $('.' + attachingEle).append(img);
}

let elementToMove = 'js-move-finishes';
let desktopAnchor = 'wheel-detail-stage';
let mobileAnchor = 'wheel-detail-info';

if ($('.' + elementToMove).length) {
  if (Foundation.MediaQuery.current === 'small') {
    moveElement(elementToMove, mobileAnchor);
  }
  $('.' + elementToMove).removeClass('hide');

  $(window).on('changed.zf.mediaquery', function () {
    if (Foundation.MediaQuery.atLeast('medium')) {
      moveElement(elementToMove, desktopAnchor);
    } else {
      moveElement(elementToMove, mobileAnchor);
    }
  });
}

 // Get see on vehicle image
  function detailCarImage(car, year, make, model, drivebody, submodel, wheel, color, body, configid, fmk) {
    var urlOptions = [
      'year=' + year,
      'make=' + make,
      'model=' + model,
      'drivebody=' + encodeURIComponent(drivebody),
      'submodel=' + submodel,
      'wheel=' + wheel,
      'color=' + color,
      'fmk=' + fmk,
      'ID=' + configid
    ];
    try {
      $.getJSON('https://www.iconfigurators.app/ap-json/ap-image-fgap-fmk-part.aspx?' + urlOptions.join('&'), function getCarImage(data) {
        if (data.Result > 0) {
          car.find('img').attr('src', data.img[0].src);

          $('#vehicle-colors').html('');
          var colorIds = data.img[0].colorID;
          var colorNames = data.img[0].colorName;

          for (var index = 0; index < colorIds.length; index++) {
            if (colorIds[index] == color) {
              $('#vehicle-colors').append('<option value="' + colorIds[index] + '" selected>' + colorNames[index] + '</option>');
            } else {
              $('#vehicle-colors').append('<option value="' + colorIds[index] + '">' + colorNames[index] + '</option>');
            }
          }

					// Create body style select
          $('#vehicle-body').html('');
          var bodyNames = data.img[0].BodyStyle;

          for (var j = 0; j < bodyNames.length; j++) {
            if (bodyNames[j] === body) {
              $('#vehicle-body').append('<option data-id="' + j + '" value ="' + bodyNames[j] + '" selected>' + bodyNames[j] + '</option>');
            } else {
              $('#vehicle-body').append('<option data-id="' + j + '" value ="' + bodyNames[j] + '" >' + bodyNames[j] + '</option>');
            }
          }

          $('#see-on-vehicle-detail > a').attr('href', function replaceUrl(i, a) {
            return a.replace(/(&body=)([0-9]+)?(&color=)([0-9]+)?/ig, '$1' + $('#vehicle-body').find(':selected').data('id') + '$3' + $('#vehicle-colors').val());
          });

				}	else {
          car.find('.img-wrapper')
            .addClass('no-fitment')
            .find('.error')
              .text('We do not currently have an image of this wheel on your vehicle. Check back soon.');
        }
      });
    } catch (err) {
      car.find('.img-wrapper')
        .addClass('no-fitment')
        .find('.error')
          .text('Error Loading Image for Your Vehicle');
    }
  }

  // Set Dropdowns
  function setDropdownHandlers(car, year, make, model, drivebody, submodel, configid, fmk) {
    // Bind the color select
    $('#vehicle-colors').change(function changeColor() {
      var body = $('#vehicle-body').val();
      var color = $('#vehicle-colors').val();
      var wheel = $('#see-on-vehicle-detail').attr('data-wheel');
      $('#see-on-vehicle-detail > a').attr('href', function replaceLink(i, a) {
        return a.replace(/(&color=)([0-9]+)?/ig, '$1' + color);
      });
      detailCarImage(car, year, make, model, drivebody, submodel, wheel, color, body, configid, fmk);
    });
		// Bind the body select
    $('#vehicle-body').change(function changeBody() {
      var body = $('#vehicle-body').val();
      var color = 0;
      var wheel = $('#see-on-vehicle-detail').attr('data-wheel');
      $('#see-on-vehicle-detail > a').attr('href', function replaceLink(i, a) {
        return a.replace(/(&body=)([0-9]+)?(&color=)([0-9]+)?/ig, '$1' + $('#vehicle-body').find(':selected').data('id') + '$3');
      });
      detailCarImage(car, year, make, model, drivebody, submodel, wheel, color, body, configid, fmk);
    });
  }

  // Check if vehicle is set in session first
  if ($('#see-on-vehicle-detail').length) {
    var car = $('#see-on-vehicle-detail');
    var year = car.data('year');
    var make = car.data('make');
    var model = car.data('model');
    var drivebody = car.data('drivebody');
    var submodel = car.data('submodel');
    var wheel = car.attr('data-wheel');
    var configid = car.data('config');
    var option = car.data('option');
    var fmk = car.data('option');
    if (typeof option === 'undefined') {
      option = '';
    }
    detailCarImage(car, year, make, model, drivebody, submodel, wheel, 0, '', configid, fmk);
    setDropdownHandlers(car, year, make, model, drivebody, submodel, configid, fmk);
  }


// zoom
/*
$('.zoom').zoom({
  callback: function(){
    // get image path
    var toPopup = $(this).attr('src');
    // initiate popup
    $(this).click(function(){
      $.magnificPopup.open({
        items: {
          src: toPopup
        },
        type: 'image'
      });
    });
  }
});
*/








