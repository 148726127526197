$('#wheel-filter-search select').change(function(){
  $('.accordion-filter__container').submit();
});

//wheel brands letter search
$('.brand-letter-search a').on('click',function(e){
  e.preventDefault();

  if($(this).hasClass('active-letter')) {

    $(this).removeClass('active-letter');
    $('.products-list > .column').each(function(){
      let $thisLetter = $(this).attr('data-letter');
      $(this).removeClass('hide-brand-item');
    });

  } else {

    // top
    let $letter = $(this).attr('href');

    $('.brand-letter-search #wheel-filter-search ul li').each(function(){
      $(this).find('a').removeClass('active-letter');
    });

    $(this).addClass('active-letter');
    
    // listing
    $('.products-list > .column').each(function(){
      let $thisLetter = $(this).attr('data-letter');
      if($letter == '0-9') {
        if(isNaN($thisLetter)) {
          $(this).addClass('hide-brand-item');
        } else {
          $(this).removeClass('hide-brand-item');
        }
      } else {
        if($thisLetter != $letter) {
          $(this).addClass('hide-brand-item');
        } else {
          $(this).removeClass('hide-brand-item');
        }
      }
    });
  }
});


// helpful functions
function trimString(obj){
  var L = (obj.length -1);
  obj = obj.substring(0, L);
  return obj;  
}
function removeVal(obj,value){
   var arrObj = obj.split(',')
   if(arrObj.length > 1){
   for( var i = 0; i < arrObj.length; i++){ 
           if ( arrObj[i] === value) {
                  arrObj.splice(i, 1); 
           }
   }
   obj = arrObj.join(",")
   }else{
        obj ='';
   }
   return obj;
}