(function ($) {
  $.fn.extend({
    appguide: function (params) {
      var defaults = {
        configID: 28445,
        year: 0,
        make: 0,
        model: 0,
        submodel: 0,
        size: 0,
        apiURL: '//iconfigurators.app/json2/',
        yearsSelector: '.vehicle-years',
        makesSelector: '.vehicle-makes',
        modelsSelector: '.vehicle-models',
        submodelsSelector: '.vehicle-submodels',
        sizesSelector: '.vehicle-sizes',
        clearSelector: '#fitmentClear',
        submitButton: '#fitmentSubmit',
        yearsLabel: 'Select Year',
        makesLabel: 'Select Make',
        modelsLabel: 'Select Model',
        submodelsLabel: 'Select Submodel',
        sizesLabel: 'Select Size',
        noWheelsMessage: 'Sorry, we do not have wheels that fit your vehicle.',
        failSubmissionMsg: 'You must fill in all the fields',
        redirectTo: '/wheels/?fit=1',
        redirectOnClear: '',
        saveSessionTo: '/ajax/createSession.cfc',
        useJson: 0,
        hideMakes: false,
        submitCallback: null,
        setDefaultSize: false,
      };
      return this.each(function (i, el) {
        function vehicleSelector($el, settings) {
          this.getJSON = function (urlParams, callback, jsonObj) {
            var self = this;
            var url = self.$el.apiURL + '?id=' + self.$el.configID + urlParams;
            if (this.$el.useJson == 1) {
              url += '&callback=' + jsonObj;
              url += '&returnType=json';
              $.getJSON(url, function (data) {
                if (callback) {
                  callback.call(self, data);
                }
              }).fail(function (e, textStatus, errorThrown) {
                console.log(e);
                console.log(textStatus);
                console.log(errorThrown);
              });
            } else {
              $.ajax({
                type: 'GET',
                url: url,
                async: true,
                jsonpCallback: jsonObj,
                contentType: 'application/json',
                dataType: 'jsonp',
                success: function (data) {
                  if (callback) {
                    callback(data);
                  }
                },
                error: function (e, textStatus, errorThrown) {
                  console.log(e);
                  console.log(textStatus);
                  console.log(errorThrown);
                },
              });
            }
          };
          this.bindEvents = function () {
            this.$el.find(this.$el.yearsSelector).change(this.initYears.bind(this));
            this.$el.find(this.$el.makesSelector).change(this.initMakes.bind(this));
            this.$el.find(this.$el.modelsSelector).change(this.initModels.bind(this));
            this.$el.find(this.$el.submodelsSelector).change(this.initSubmodels.bind(this));
            this.$el.find(this.$el.sizesSelector).change(this.initSizes.bind(this));
            this.$el.find(this.$el.clearSelector).click(this.clearAll.bind(this));
            this.$el.find(this.$el.submitButton).click(this.submitFitment.bind(this));
          };
          // Years
          this.callYears = function () {
            this.getJSON('', this.renderYears.bind(this), 'yearObj');
          };
          this.renderYears = function (data) {
            var html = '<option value="">' + this.$el.yearsLabel + '</option>';
            for (var i = 0; i < data.years.length; i++) {
              // set previously saved
              var selected = '';
              if (this.$el.year == data.years[i].year) {
                selected = ' selected';
              }
              html +=
                '<option value="' +
                data.years[i].year +
                '" ' +
                selected +
                ' >' +
                data.years[i].year +
                '</option>';
            }
            this.$el
              .find(this.$el.yearsSelector)
              .removeAttr('disabled')
              .html(html);
          };
          this.initYears = function () {
            this.$el.year = this.$el.find(this.$el.yearsSelector).val();
            if (!this.$el.hideMakes) {
              this.callMakes();
            } else {
              this.callModels();
            }
            // clear steps below
            this.clearLevels(['make', 'model', 'submodel', 'size']);
            // save to session
            this.setSession('year');
          };
          // makes
          this.callMakes = function () {
            var url = '&year=' + this.$el.year;
            this.getJSON(url, this.renderMakes.bind(this), 'makeObj');
          };
          this.renderMakes = function (data) {
            var html = '<option value="">' + this.$el.makesLabel + '</option>';
            for (var i = 0; i < data.makes.length; i++) {
              // for text-only app guides
              if (!data.makes[i].makeid) {
                data.makes[i].makeid = data.makes[i].make;
              }
              var selected = '';
              if (this.$el.make == data.makes[i].makeid) {
                selected = ' selected';
              }
              html +=
                '<option value="' +
                data.makes[i].makeid +
                '" ' +
                selected +
                '>' +
                data.makes[i].make +
                '</option>';
            }

            this.$el
              .find(this.$el.makesSelector)
              .removeAttr('disabled')
              .html(html);
          };
          this.initMakes = function () {
            var $make = this.$el.find(this.$el.makesSelector);
            this.$el.make = $make.val();
            this.callModels();
            // clear steps below
            this.clearLevels(['model', 'submodel', 'size']);
            // save to session
            this.setSession('make', $make.find(':selected').text());
          };
          // models
          this.callModels = function () {
            var url = '&year=' + this.$el.year;
            url += '&make=' + this.$el.make;
            this.getJSON(url, this.renderModels.bind(this), 'modelObj');
          };
          (this.renderModels = function (data) {
            var html = '<option value="">' + this.$el.modelsLabel + '</option>';
            for (var i = 0; i < data.models.length; i++) {
              // for text-only app guides
              if (!data.models[i].modelid) {
                data.models[i].modelid = data.models[i].model;
              }
              var selected = '';
              if (this.$el.model == data.models[i].modelid) {
                selected = ' selected';
              }
              html +=
                '<option value="' +
                data.models[i].modelid +
                '" ' +
                selected +
                '>' +
                data.models[i].model +
                '</option>';
            }
            this.$el
              .find(this.$el.modelsSelector)
              .removeAttr('disabled')
              .html(html);
          }),
          (this.initModels = function () {
            var $model = this.$el.find(this.$el.modelsSelector);
            this.$el.model = $model.val();
            this.callSubmodels();
            // clear steps below
            this.clearLevels(['submodel', 'size']);
            // save to session
            this.setSession('model', $model.find(':selected').text());
          });
          /*   Submodels */
          this.callSubmodels = function () {
            var url = '&year=' + this.$el.year;
            url += '&make=' + this.$el.make;
            url += '&model=' + this.$el.model;
            var jsonObj = 'subModelSZObj';
            // for text-only app guides
            if (isNaN(parseFloat(this.$el.make)) || isNaN(parseFloat(this.$el.make))) {
              jsonObj = 'subModelObj';
            }
            this.getJSON(url, this.renderSubmodels.bind(this), jsonObj);
          };
          this.renderSubmodels = function (data) {
            var html = '<option value="">' + this.$el.submodelsLabel + '</option>';
            for (var i = 0; i < data.submodels.length; i++) {
              // for text-only app guides
              if (!data.submodels[i].submodelid) {
                data.submodels[i].submodelid = data.submodels[i].submodel;
              }
              var selected = '';
              if (this.$el.submodel == data.submodels[i].submodelid) {
                selected = ' selected';
              }
              html +=
                '<option value="' +
                data.submodels[i].submodelid +
                '" ' +
                selected +
                '>' +
                data.submodels[i].submodel +
                '</option>';
            }
            this.$el
              .find(this.$el.submodelsSelector)
              .removeAttr('disabled')
              .html(html);
          };
          this.initSubmodels = function () {
            var $submodel = this.$el.find(this.$el.submodelsSelector);
            this.$el.submodel = $submodel.val();
            // save to session
            this.setSession('submodel', $submodel.find(':selected').text());
            if (isNaN(parseFloat(this.$el.make)) || isNaN(parseFloat(this.$el.model))) {
              // for text only app guides
              if (this.$el.submitCallback) {
                this.$el.submitCallback();
              }
              if (this.$el.redirectTo) {
                window.location.href = this.$el.redirectTo;
              }
            } else {
              // for ACES complaint app guides
              if (this.$el.setDefaultSize) {
                this.callSizes(this.setSizes.bind(this));
              } else {
                this.callSizes(this.renderSizes.bind(this));
              }
              this.clearLevels(['size']);
            }
          };
          this.callSizes = function (callback) {
            var url = '&year=' + this.$el.year;
            url += '&make=' + this.$el.make;
            url += '&model=' + this.$el.model;
            url += '&submodel=' + this.$el.submodel;

            this.getJSON(url, callback, 'sizeSZObj');
          };
          (this.renderSizes = function (data) {
            if (data.sizes.length > 0) {
              var html = '<option value="">' + this.$el.sizesLabel + '</option>';
              for (var i = 0; i < data.sizes.length; i++) {
                var selected = '';
                if (this.$el.size == data.sizes[i].sizeid) {
                  selected = ' selected';
                }
                html +=
                  '<option value="' +
                  data.sizes[i].sizeid +
                  '"' +
                  selected +
                  '>' +
                  data.sizes[i].size +
                  '</option>';
              }
              this.$el
                .find(this.$el.sizesSelector)
                .removeAttr('disabled')
                .html(html);
            } else {
              if (!this.$el.firstLoad) {
                this.displayNoSizesWarning();
              }
            }
            this.$el.firstLoad = false;
          }),
          (this.setSizes = function (data) {
            // set first size from ajax request as default size
            this.$el.size = data.sizes[0].sizeid;
            // render sizes
            this.renderSizes(data);
            // save to session
            this.setSession('size', data.sizes[0].sizeid);
            if (this.$el.redirectTo) {
              window.location.href = this.$el.redirectTo + '&sizeID=' + this.$el.size;
            }
          });
          this.initSizes = function () {
            var $size = this.$el.find(this.$el.sizesSelector);
            var value = this.$el;
            this.$el.size = $size.val();
            if ($size.find(':selected').text() === 'Select Size') {
              return;
            }
            if ($.magnificPopup) {
              $.magnificPopup.open({
                items: {
                  src: '<div class="white text-center processing"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                },
                type: 'inline',
              });
            }
            // save to session
            this.setSession('size', $size.find(':selected').text(), function () {
              if (value.redirectTo) {
                window.location.href = value.redirectTo + '&sizeID=' + value.size;
              }
            });
            if (this.$el.submitCallback) {
              this.$el.submitCallback();
            }
          };
          this.clearAll = function () {
            this.clearLevels(['year', 'make', 'model', 'submodel', 'size']);
            if (this.$el.redirectOnClear != '') {
              window.location.href = this.$el.redirectOnClear;
            }
          };
          this.clearLevels = function (arrLevels) {
            for (var i = 0; i < arrLevels.length; i++) {
              if (this.$el.hideMakes && arrLevels[i] == 'make') {
                continue;
              }
              this.$el[arrLevels[i]] = 0;
              var selector = this.$el[arrLevels[i] + 'sSelector'];
              this.$el.find(selector).val('');
              this.clearSession(arrLevels[i]);
              if (arrLevels[i] !== 'year') {
                var label = this.$el[arrLevels[i] + 'sLabel'];
                this.$el
                  .find(selector)
                  .html('<option value="">' + label + '</option>')
                  .attr('disabled', 'disabled');
                this.clearSession('str' + arrLevels[i]);
              }
            }
          };
          this.clearSession = function (variable) {
            if (this.$el.hideMakes && (variable == 'make' || variable == 'strMake')) {
              return false;
            }
            var url = this.$el.saveSessionTo + '?method=clearSession';
            url += '&name=' + variable;
            $.getJSON(url);
          };
          this.setSession = function (type, stringValue, callback) {
            var url = this.$el.saveSessionTo + '?method=set' + type;
            url += '&' + type + '=' + this.$el[type];
            url += '&hideMakes=' + this.$el.hideMakes;
            if (stringValue) {
              url += '&str' + type + '=' + stringValue;
            }
            $.getJSON(url, function (data) {
              if (callback) {
                callback();
              }
            });
          };
          this.displayNoSizesWarning = function () {
            var message = '';
            try {
              $.magnificPopup.open({
                items: {
                  src: '<div class="white-popup"><div class="mfp-close"></div>' +
                    this.$el.noWheelsMessage +
                    '</div>',
                },
                type: 'inline',
              });
            } catch (err) {
              alert(this.$el.noWheelsMessage);
            }
          };
          this.submitFitment = function (event) {
            if (this.$el.submitCallback) {
              this.$el.submitCallback();
            }
            if (!this.$el.redirectTo) {
              return false;
            }
            var fail = true;
            if (this.$el.year && this.$el.make && this.$el.model && this.$el.submodel) {
              if (isNaN(parseFloat(this.$el.make)) || isNaN(parseFloat(this.$el.make))) {
                // for text app guides
                fail = false;
              } else if (this.$el.size) {
                // for aces app guides;
                fail = false;
              }
            }
            if (fail) {
              try {
                $.magnificPopup.open({
                  items: {
                    src: '<div class="white-popup">' + this.failSubmissionMsg + '</i></div>',
                  },
                  type: 'inline',
                });
              } catch (err) {
                alert(failSubmissionMsg);
              }
            } else {
              window.location.href = this.$el.redirectTo + '&sizeID=' + this.$el.size;
            }
          };
          this.$el = $el;
          this.$el.configID = settings.configID;
          this.$el.year = settings.year;
          this.$el.make = settings.make;
          this.$el.model = settings.model;
          this.$el.submodel = settings.submodel;
          this.$el.size = settings.size;
          this.$el.apiURL = settings.apiURL;
          this.$el.yearsSelector = settings.yearsSelector;
          this.$el.makesSelector = settings.makesSelector;
          this.$el.modelsSelector = settings.modelsSelector;
          this.$el.submodelsSelector = settings.submodelsSelector;
          this.$el.sizesSelector = settings.sizesSelector;
          this.$el.clearSelector = settings.clearSelector;
          this.$el.submitButton = settings.submitButton;
          this.$el.yearsLabel = settings.yearsLabel;
          this.$el.makesLabel = settings.makesLabel;
          this.$el.modelsLabel = settings.modelsLabel;
          this.$el.submodelsLabel = settings.submodelsLabel;
          this.$el.sizesLabel = settings.sizesLabel;
          this.$el.noWheelsMessage = settings.noWheelsMessage;
          this.$el.failSubmissionMsg = settings.failSubmissionMsg;
          this.$el.redirectTo = settings.redirectTo;
          this.$el.saveSessionTo = settings.saveSessionTo;
          this.$el.useJson = settings.useJson;
          this.$el.redirectOnClear = settings.redirectOnClear;
          this.$el.hideMakes = settings.hideMakes;
          this.$el.firstLoad = true;
          this.$el.submitCallback = settings.submitCallback;
          this.$el.setDefaultSize = settings.setDefaultSize;

          this.bindEvents($el);
          if (this.$el.configID) {
            this.callYears();
          }
          if (this.$el.year) {
            this.callMakes();
          }
          if (this.$el.year && this.$el.make) {
            this.callModels();
          }
          if (this.$el.year && this.$el.make && this.$el.model) {
            this.callSubmodels();
          }
          if (this.$el.year && this.$el.make && this.$el.model && this.$el.submodel) {
            // Aces compliant app guides only
            if (!isNaN(parseFloat(this.$el.make)) && !isNaN(parseFloat(this.$el.make))) {
              this.callSizes(this.renderSizes.bind(this));
            }
          }
          return this;
        }
        var $el = $(el);
        var opts = $.extend({}, defaults, params);
        var vehicleSelector = new vehicleSelector($el, opts);
      });
    },
  });
})(jQuery);